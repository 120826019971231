import { processRiskRating, processIdVerification, capitalizeFirstLetterOfEachWord } from 'utils';

export const ClientHeaders = {
  columns:  [
    {
      key: 'registrationDate',
      label: 'Registration date',
      dataCellStyles: {
        width: 160
      }
    },
    {
      key: 'uid',
      label: 'Client ID'
    },
    {
      key: 'firstName',
      label: 'First name'
    },
    {
      key: 'lastName',
      label: 'Last name'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'country',
      label: 'Country',
      processCellData: capitalizeFirstLetterOfEachWord
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'riskRating',
      label: 'Risk rating'
    },
    {
      key: 'idVerification',
      label: 'ID verification'
    }
  ],
  searchColumns: ['firstName', 'lastName', 'email', 'uid'],
  sortableColumns: ['registrationDate', 'status', 'idVerification'],
  statusData: [
    {
      key: 'status',
      type: 'oval',
      valid: ['REGISTERED|REVIEW', 'APPROVED|VERIFIED', 'TRADED|TRADED', 'FUNDED|FUNDED', 'DECLINED|DECLINED', 'REGFULL|NEW', 'DORMANT|DORMANT']
    },
    {
      key: 'riskRating',
      type: 'ellipse',
      valid: ['Low|VERIFIED', 'High|WARNING', 'Very high|ALERT', 'Medium|REVIEW'],
      processStatus: processRiskRating
    },
    {
      key: 'idVerification',
      type: 'oval',
      valid: ['Resubmission required|NEW', 'Verified|VERIFIED', 'Manual review required|WARNING', 'Rejected|DEFAULT', 'Pending|REVIEW', 'Disqualified|TRANSPARENT'],
      ignoreStatus: ['SDK_FLOW_COMPLETED', 'INIT'],
      processStatus: processIdVerification
    }
  ],
  dateColumns: ['registrationDate'],
  mobileColumns: [
    {
      key: 'registrationDate'
    },
    {
      key: 'uid'
    },
    {
      key: 'status',
      sortLabelStyles: {
        textAlign: 'center'
      },
      labelStyles: {
        width: 'auto !important'
      }
    },
    {
      key: 'riskRating',
      labelStyles: {
        width: '37px !important'
      },
      dataCellStyles: {
        textAlign: 'center'
      }
    }
  ],
  ellipseColumns: ['firstName', 'lastName']
};