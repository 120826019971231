/* eslint-disable react-hooks/exhaustive-deps */
import { useState, ChangeEvent, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, IconButton, InputAdornment, FormControl, InputLabel, Input, TextField, Chip, Tooltip, Select, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import _ from 'lodash';
import moment from 'moment';
import Check from 'components/icons/Check';
import Edit from 'components/icons/Edit';
import CaretGreen from 'components/icons/CaretGreen';
import EditPencil from 'components/icons/EditPencil';
import CssChip from './CssChip';

interface FieldData {
  label: string
  value: any
  key?: number
  name?: string
  verified?: boolean
  ellipses?: boolean
  labelVariant?: any
  valueSx?: any
  labelSx?: any
  boxSx?: any
  edit?: boolean
  conversion?: string
  enableAutocomplete?: boolean
  helperText?: string
  isDate?: boolean
  isPercentage?: boolean
  isCountry?: boolean
  permissionCheck?: boolean
  isEditHover?: boolean
  isFieldHover?: boolean
  fieldType?: string
  dropdownList?: any[]
  fieldIndex?: number
  inputData?: any
  fieldError?: string
  isBoolean?: boolean
  pickLable?: boolean
  isDisabled?: boolean
  labelStyles?: any
  isDisplayZero?: boolean
  valueType?: string
  isChip?: boolean
  signType?: string
  onEdit?: (event: any, name: string, index?: number) => void
  onInputChangeValue?: (event: any, name: string, inputData: any, index?: number, isDate?: boolean, pickValueName?: string) => void
  onToggleHover?: (name: string, index?: number) => void
  onUpdate?: (value: string, name: string, valueType?: string) => void
  onAutocompleteBlur?: (name: string, value: any) => void
  processStatus?: (value: string) => string | undefined
};

const StyledLabel = styled(Typography)(({ theme }) => ({
  lineHeight: 2,
  fontSize: '.875rem',
  fontFamily: 'Roboto'
}));

const StyledValue = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  lineHeight: 2,
  fontSize: '1.25rem',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'space-between',
  '& .ellipse-text': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400
  },
  '&.tags-view': {
    display: 'inline-table',
    width: '100%',
    height: 46,
    position: 'relative',
    justifyContent: 'flex-start',
    paddingBottom: 10,
    '& .MuiChip-root': {
      marginRight: 5,
      position: 'relative',
      top: 8
    },
    '& .MuiButtonBase-root': {
      position: 'absolute',
      right: 0,
      bottom: '-2px'
    }
  },
  '&.date-view': {
  },
  '&.negative': {
    color: '#FF802C'
  },
  '&.positive': {
    color: '#0CC'
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '1.25rem'
  }
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontSize: '1.180rem',
    color: '#000000DE'
  },
  '& .MuiInputBase-root': {
    paddingTop: 16
  },
  '& .MuiAutocomplete-endAdornment': {
    height: 34,
    width: 34,
    position: 'relative',
    bottom: 4,
    right: '-28px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiAutocomplete-clearIndicator': {
    visibility: 'visible',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.icon-edit-auto-complete': {
    height: 24,
    width: 24,
    backgroundColor: 'transparent'
  },
  '& .icon.edit': {
    '& svg': {
      color: '#E5E5E5',
      '&:hover': {
        color: '#04F8DA'
      }
    }
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: '#04F8DA'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 10,
    paddingBottom: 10
  },
  '&.phone': {
    width: '82%'
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#5B5959',
  width: '100%',
  fontSize: '0.875rem',
  '&:before, &:after, &:hover': {
    borderBottom: 0
  },
  '&.MuiInput-underline:hover': {
    borderBottom: 0
  },
  '& .MuiSelect-select': {
    padding: '11.5px 14px',
    borderColor: '#04F8DA',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: '#04F8DA'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA',
    borderWidth: 1,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 12,
    paddingBottom: 12
  },
  '& .MuiSelect-icon': {
    right: 12,
    top: 12
  },
  '&.calling-code': {
    width: '15%'
  }
}));

const ViewField = ({ label, value, name, key, verified, ellipses, labelVariant = 'body1', valueSx, labelSx, boxSx, edit, conversion, enableAutocomplete, helperText, isDate, isPercentage, isCountry, permissionCheck, isEditHover, isFieldHover, fieldType, dropdownList, fieldIndex, inputData, fieldError, isBoolean, pickLable, isDisabled, labelStyles, isDisplayZero, valueType, isChip, signType, onEdit, onInputChangeValue, onToggleHover, onUpdate, onAutocompleteBlur, processStatus }: FieldData) => {
  const [changeValue, setChangeValue] = useState<any>('');
  const [enableInputAdornment, setEnableInputAdornment] = useState<boolean>(false);
  const getProcessValue = (value: any) => {
    if (value && !_.isArray(value) && conversion && conversion === 'LOWERCASE') {
      return value.toLowerCase();
    } else if (value && _.isArray(value) && value.length > 0) {
      return (_.map(value, (v) => (<Chip label={v} />)));
    } else if (value && isDate) {
      return moment(value).format('MM.DD.YYYY');
    } else if (value && isPercentage) {
      return value + '%';
    } else if ((value === true || value === false) && isBoolean && name === 'isUsTaxpayer') {
      return value === true ? 'Yes' : 'No';
    } else if (value && isCountry && dropdownList?.length) {
      const c = _.filter(dropdownList, (c) => c.value === value)[0];
      return c.label;
    } else if (value && pickLable && dropdownList?.length && name && ['status', 'annualIncome', 'netWorth'].includes(name)) {
      const c = _.filter(dropdownList, (c) => c.value === value)[0];
      return c && c.label;
    } else if (value === 0 && isDisplayZero) {
      return '0';
    }
    return (value && _.isArray(value) && value.length === 0) ? '-' : (value || (edit ? '' : '-'));
  };

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setChangeValue(event.target.value);
  };

  const handleChange = (event: any, selectedItems: any) => {
    if (!selectedItems.length) {
      setEnableInputAdornment(true);
    } else {
      setEnableInputAdornment(false);
    }
    setChangeValue(selectedItems);
  };

  useEffect(() => {
    if (name === 'tags') {
      if (!value?.length) {
        setEnableInputAdornment(true);
      } else {
        setEnableInputAdornment(false);
      }
    }
    setChangeValue(value);
  }, [value]);

  return (
    <Box sx={boxSx} {...(key && {key: key})}>
      {!edit && 
        <>
          <StyledLabel variant={labelVariant} {...(labelStyles && {sx: labelStyles})} sx={labelSx}>{label}</StyledLabel>
          {isChip && processStatus ? 
            <StyledValue variant='h6' sx={valueSx} {...(enableAutocomplete && {className: 'tags-view'})} {...(isDate && {className: 'date-view'})} {...(isEditHover && name && onToggleHover && {onMouseEnter: () => onToggleHover(name, fieldIndex), onMouseLeave: () => onToggleHover(name, fieldIndex)})}>
              <CssChip label={getProcessValue(value)} className={processStatus(value)} sx={{mb: .075}} />
            </StyledValue> : 
            <StyledValue variant='h6' sx={valueSx} {...(enableAutocomplete && {className: 'tags-view'})} {...(signType && {className: signType})} {...(isDate && {className: 'date-view'})} {...(isEditHover && name && onToggleHover && {onMouseEnter: () => onToggleHover(name, fieldIndex), onMouseLeave: () => onToggleHover(name, fieldIndex)})}>
              {ellipses ? <span className='ellipse-text'>{value || '-'}</span> : getProcessValue(value)}
              {verified === false && !isEditHover && <Check sx={{top: 5}} />}
              {verified === true && !isEditHover && <Check fill='#04F8DA' sx={{top: 5}} />}
              {permissionCheck && edit === false && onEdit && name && !isEditHover && <Tooltip title='Edit'><StyledIconButton onClick={(event: any) => onEdit(event, name, fieldIndex)}><Edit fill='currentColor' sx={{height: 24}} className='edit' /></StyledIconButton></Tooltip>}
              {permissionCheck && edit === false && onEdit && name && isEditHover && <Box>
                {verified === false && <Check sx={{top: 10}} />}
                {verified === true && <Check fill='#04F8DA' sx={{top: 10}} />}
                {isFieldHover && <Tooltip title='Edit'><StyledIconButton onClick={(event: any) => onEdit(event, name, fieldIndex)} sx={{pb: 0}}><EditPencil sx={{height: 24}} /></StyledIconButton></Tooltip>}
              </Box>
              }
            </StyledValue>
          }
        </>
      }
      {edit === true && !isEditHover && onUpdate && name && <>
        {enableAutocomplete ? <StyledAutocomplete
          multiple
          options={changeValue || []}
          freeSolo
          value={changeValue || []}
          onChange={(event, value) => handleChange(event, value)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant='standard'
                label='Tags'
                helperText={<Typography variant='caption' sx={{fontStyle: 'italic'}}>{helperText}</Typography>}
                InputLabelProps={{
                  shrink: true
                }}
                {...(enableInputAdornment && {
                  InputProps: {
                    endAdornment: <Tooltip title='Save' sx={{top: '-10px', right: 10}}><StyledIconButton onClick={(event: any) => onUpdate(changeValue, name)} className={'icon-edit-auto-complete'} ><Check fill='#04F8DA' sx={{top: 5}} /></StyledIconButton></Tooltip>
                  }
                })}
              />
            )}
          }
          clearText={''}
          clearIcon={<Tooltip title='Save' sx={{top: '-2px'}}><StyledIconButton onClick={(event: any) => onUpdate(changeValue, name)} className={'icon-edit-auto-complete'} ><Check fill='#04F8DA' sx={{top: 5}} /></StyledIconButton></Tooltip>}
          {...(onAutocompleteBlur && {onBlur: () => onAutocompleteBlur(name, changeValue)})}
          ListboxProps={{
            style: { display: 'none' }
          }}
        /> :
        <FormControl sx={{ width: '100%', mt: .9 }} variant='standard'>
          <InputLabel htmlFor={name} sx={{ fontSize: '1.180rem', color: '#000000DE' }} shrink>{label}</InputLabel>
          <Input
            id={name}
            type={isDate ? 'date' : 'text'}
            value={changeValue}
            onChange={onChangeValue}
            sx={{pt: 1.2, pb: 1.2}}
            endAdornment={
              <InputAdornment position='end'>
                <Tooltip title='Save' sx={{top: '-2px'}}><StyledIconButton onClick={(event: any) => onUpdate(changeValue, name, valueType || '')}><Check fill='#04F8DA' sx={{top: 5}} /></StyledIconButton></Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>}
      </>}

      {edit === true && onInputChangeValue && name && fieldType === 'TEXT' && <>
        <StyledLabel variant={labelVariant}>{label}</StyledLabel>
        <StyledTextField
          variant='outlined'
          value={value}
          name={name}
          onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex)}
          {...(isDisabled && {disabled: true})}
          {...(fieldError && {error: true, helperText: fieldError})}
       />
      </>
      }
      {edit === true && onInputChangeValue && name && fieldType === 'SELECT' && dropdownList && <>
        <StyledLabel variant={labelVariant}>{label}</StyledLabel>
        <StyledSelect
          variant='outlined'
          value={value}
          IconComponent={CaretGreen}
          onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex)}
          {...(isDisabled && {disabled: true})}
        >
          {dropdownList.map((d: any, i: number) => <MenuItem value={d.value} key={i}>{d.label}</MenuItem>)}
        </StyledSelect>
      </>}
      {edit === true && onInputChangeValue && name && fieldType === 'DATE' && <>
        <StyledLabel variant={labelVariant}>{label}</StyledLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={value}
            onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex, true)}
            renderInput={(params) => <StyledTextField
              {...params}
              size='small'
              {...(fieldError && {error: true, helperText: fieldError})}
            />}
            PopperProps={{
              sx: {
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#04F8DA',
                  color: '#000'
                },
                '& .MuiPickersDay-root:hover': {
                  backgroundColor: 'transparent',
                  border: '1px solid #04F8DA'
                }
              }
            }}
          />
        </LocalizationProvider>
      </>}
      {edit === true && onInputChangeValue && name && fieldType === 'SELECT_TEXT' && dropdownList && <>
        <StyledLabel variant={labelVariant}>{label}</StyledLabel>
        <Box sx={{display: 'flex'}} justifyContent={'space-between'}>
          <StyledSelect
            variant='outlined'
            value={name === 'phone' && inputData && inputData.callingCodeValue ? inputData.callingCodeValue : value}
            IconComponent={CaretGreen}
            onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex, false, 'callingCode')}
            className='calling-code'
          >
            {dropdownList.map((d: any, i: number) => <MenuItem value={d.value} key={i}>{d.label}</MenuItem>)}
          </StyledSelect>
          <StyledTextField
            variant='outlined'
            value={name === 'phone' && inputData && inputData.phoneValue ? inputData.phoneValue : value}
            onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex, false, 'phone')}
            className='phone'
          />
        </Box>
      </>}
      {edit === true && onInputChangeValue && name && fieldType === 'TEXT_TEXT' && <>
        <StyledLabel variant={labelVariant}>{label}</StyledLabel>
        <StyledTextField
          variant='outlined'
          value={name === 'address' && inputData && inputData['address.line1Value'] ? inputData['address.line1Value'] : value}
          sx={{mb: 2}}
          onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex,  false, 'address.line1')}
          name='address'
          {...(fieldError && {error: true, helperText: fieldError})}
       />
       <StyledTextField
         variant='outlined'
         value={name === 'address' && inputData && (inputData['address.line2Value'] || !inputData['address.line2Value']) ? inputData['address.line2Value'] : value}
         onChange={(event) => onInputChangeValue(event, name, inputData,fieldIndex, false, 'address.line2')}
       />
      </>
      }
    </Box>
  );
};

export default ViewField;