/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, MouseEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Grid, Divider, IconButton, useMediaQuery, Typography } from '@mui/material';
import CssContainer from 'components/CssContainer';
import CssBreadcrumbs from 'components/CssBreadcrumbs';
import CssBox from 'components/CssBox';
import CssTitle from 'components/CssTitle';
import CssFlexBox from 'components/CssFlexBox';
import CssChip from 'components/CssChip';
import CssTabs from 'components/CssTabs';
import BackArrow from 'components/icons/BackArrow';
import { ProfileInfo, Accounts, Transfers, Orders, CashAdjustments } from './panels';
import Kyc from './Kyc';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';
import SearchResults from 'components/SearchResults';
import { ClientHeaders } from 'constants/client-table-head-columns';

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { searchQuery, isSearchClosed, setEnableSearchField, setLegalEntity, clientPage, setClientProfileDetails, clientProfileInfo, setClientProfileInfo, setSelectedTabType, setSelectedButtonType } = useContext(GlobalContext);
  const { getClientProfileApi } = useRequests();
  const getClientProfile = useApi(getClientProfileApi);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const valid = ['REGISTERED|REVIEW', 'APPROVED|VERIFIED', 'TRADED|TRADED', 'FUNDED|FUNDED', 'DECLINED|DECLINED', 'REGFULL|NEW', 'DORMANT|DORMANT'];
  const poaValid = ['Manual review required|WARNING', 'Rejected|DEFAULT', 'Pending|REVIEW', 'Approved|VERIFIED'];
  const ignoreStatus = ['SDK_FLOW_COMPLETED', 'INIT'];
  const [statusClassName, setStatusClassName] = useState<string>('');
  const [poaStatusClassName, setPoAStatusClassName] = useState<string>('');
  const [enableSearchResults, setEnableSearchResults] = useState<boolean>(false);
  const [hideKycPanel, setHideKycPanel] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('1');

  const breadcrumbs = [
    {
      name: 'Clients',
      url: '/clients',
      key: 1,
      data: {clientPage: clientPage}
    },
    {
      name: clientProfileInfo ? `${clientProfileInfo?.firstName || '-'} ${clientProfileInfo?.lastName || '-'}` : '- -',
      key: 2
    }
  ];

  const handleProfileInfoTab = () => {
    setHideKycPanel(false);
  };

  const handleAccountTab = () => {
    setHideKycPanel(true);
  };

  const handleTransfersTab = () => {
    setHideKycPanel(true);
    setSelectedButtonType('DEPOSIT');
  };

  const handleOrdersTab = () => {
    setHideKycPanel(true);
    setSelectedTabType('ORDERS');
    setSelectedButtonType('EQUITY');
  };

  const handleCashAdjustmentsTab = () => {
    setHideKycPanel(true);
  };

  const tabs = [
    {
      label: 'Personal info',
      value: 1,
      onTabClick: handleProfileInfoTab,
      tabPanelComponent: clientProfileInfo ? <ProfileInfo /> : null
    },
    {
      label: 'Account',
      value: 2,
      onTabClick: handleAccountTab,
      tabPanelComponent: <Accounts />
    },
    {
      label: 'Payments',
      value: 3,
      onTabClick: handleTransfersTab,
      tabPanelComponent: <Transfers />
    },
    {
      label: 'Orders',
      value: 4,
      onTabClick: handleOrdersTab,
      tabPanelComponent: <Orders />
    },
    {
      label: 'Cash Adjustments',
      value: 5,
      onTabClick: handleCashAdjustmentsTab,
      tabPanelComponent: <CashAdjustments />
    }
  ];

  const tabsData = isMobile ? tabs.concat({
    label: 'KYC',
    value: 4,
    onTabClick: () => {},
    tabPanelComponent: <Kyc />
  }) : tabs;

  const handleBackButton = () => {
    navigate('/clients');
  };

  const getProfileCall = (id: string | number | null) => {
    getClientProfile.request(id).then((res) => {
      if (res.status === 200) {
        setClientProfileInfo(res.data);
        setStatusClassName(_.split(_.find(valid, (v) => v.includes(res.data.status)), '|')[1]);
        setPoAStatusClassName(_.split(_.find(poaValid, (v) => v.includes(res.data.poaVerification)), '|')[1]);
      }
    });
  };

  const handleRowClick = (event: MouseEvent<unknown>, profile: any) => {
    setClientProfileDetails((cpds: any) => _.map(cpds, (i) => {
      if (i.value) {
        i.value = '';
      } else if (i.section && i.fields) {
        i.fields = i.fields.map((f: any, i: number) => {
          if (f?.value) {
            f.value = '';
          }
          return f;
        });
      } else if (i.rootField) {
        i.rootField.value = '';
      } else if (i.section === 'pep') {
        i.renderByField = [];
      }
      return i;
    }));
    setClientProfileInfo(null);
    setClientId(profile.uid);
    setEnableSearchResults(false);
    getProfileCall(profile.uid);
    setEnableSearchField(false);
  };

  useEffect(() => {
    getClientProfile.request(clientId).then((res) => {
      if (res.status === 200) {
        setClientProfileInfo(res.data);
        setStatusClassName(_.split(_.find(valid, (v) => v.includes(res.data.status)), '|')[1]);
        setPoAStatusClassName(_.split(_.find(poaValid, (v) => v.includes(res.data.poaVerification)), '|')[1]);
        setLegalEntity({
          brand: res.data.brand,
          marketingCompany: res.data.marketingCompany,
          regulatoryBody: res.data.regulatoryBody
        });
      }
    });
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setEnableSearchResults(true);
    }
    if (isSearchClosed) {
      setEnableSearchResults(false);
    }
  }, [searchQuery, isSearchClosed]);

  useEffect(() => {
    if (from) {
      if (from === 'TRANSACTIONS' || from === 'ORDERS') {
        setHideKycPanel(true);
      }
      setActiveTab(from === 'TRANSACTIONS' ? '3' : (from === 'ORDERS' ? '4' : '1'));
    }
  }, [from])

  return (
    <CssContainer>
      {enableSearchResults ? 
        <SearchResults headers={ClientHeaders} onRowClick={handleRowClick} />
        :
        <CssBox>
          <CssFlexBox sx={{justifyContent: 'space-between'}}>
            {!isMobile && <CssBreadcrumbs linkStack={breadcrumbs}/>}
            {isMobile && 
              <IconButton sx={{ width: 20, height: 30 }} onClick={handleBackButton}>
                <BackArrow />
              </IconButton>
            }
          </CssFlexBox>
          <Grid container sx={{ mt: isMobile ? 1.375 : 5 }}>
            <Grid item xs={(isMobile || hideKycPanel) ? 12 : 9} sx={{ pr: isMobile ? 0 : (hideKycPanel ? 0 : 4.625) }}>
              <Grid container>
                <Grid item xs={isMobile ? 9 : 12} sx={{ pt: .6 }}>
                  <CssTitle variant='h5' sx={isMobile ? { fontSize: '1.5rem', mb: .5 } : { fontSize: '1.875rem' }}>{`${clientProfileInfo?.firstName || '-'} ${clientProfileInfo?.lastName || '-'}`}</CssTitle>
                  <CssFlexBox>
                    {isMobile ?
                      <>
                        <CssTitle variant='h6' sx={{ fontSize: '.75rem', fontWeight: 700 }}>ID</CssTitle>
                        <CssTitle variant='body1' sx={{ fontSize: '.75rem', ml: 1 }}>{clientId || clientProfileInfo.uid}</CssTitle>
                        <CssTitle variant='h6' sx={{ fontSize: '.75rem', fontWeight: 700, ml: 4.375 }}>Status</CssTitle>
                        <CssChip label={clientProfileInfo?.status.toLowerCase()} className={statusClassName} sx={{ ml: 1.25, fontSize: '.75rem', position: 'relative', top: '-4px' }}/>
                      </> :
                      <>
                        <CssTitle variant='h6' sx={{ fontSize: '1rem', fontWeight: 700 }}>ID</CssTitle>
                        <CssTitle variant='body1' sx={{ ml: 1, position: 'relative', top: 1 }}>{clientId || clientProfileInfo.uid}</CssTitle>
                        <CssTitle variant='h6' sx={{ fontSize: '1rem', fontWeight: 700, ml: 4.375 }}>Status</CssTitle>
                        <CssChip label={clientProfileInfo?.status?.toLowerCase()} className={statusClassName} sx={{ ml: 1.25 }}/>
                        <CssTitle variant='h6' sx={{ fontSize: '1rem', fontWeight: 700, ml: 4.375 }}>PoA status</CssTitle>
                        {clientProfileInfo?.poaVerification && !(ignoreStatus.includes(clientProfileInfo?.poaVerification)) ? <CssChip label={clientProfileInfo?.poaVerification?.toLowerCase()} className={poaStatusClassName} sx={{ ml: 1.25 }}/> : <Typography variant='body2' sx={{ml: 1.5, mt: .5}}>&mdash;</Typography>}
                      </>
                    }
                  </CssFlexBox>
                </Grid>
              </Grid>
              <CssTabs tabs={tabsData} defaultIndex={activeTab} />
            </Grid>
            {!isMobile && <Divider orientation='vertical' flexItem />}
            {!isMobile && !hideKycPanel && <Grid item xs={2.9896} sx={{ pl: 4.625 }}>
              <Kyc />
            </Grid>}
          </Grid>
        </CssBox>
      }
    </CssContainer>
  );
};

export default Profile;