/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, MouseEvent, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { useMediaQuery, Box, Typography, Grid, IconButton, FormGroup, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssTable from 'components/CssTable';
import Filters from 'components/Filters';
import { ClientHeaders } from 'constants/client-table-head-columns';
import { Order, setAccordionExpandByFilters, checkPermissions, getQueryMap } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import CalendarBlack from 'components/icons/CalendarBlack';
import { GlobalContext } from 'context';
import ScreenDialog from 'components/ScreenDialog';
import CssButton from 'components/CssButton';
import IconFilters from 'components/icons/Filters';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import Download from 'components/icons/Download';
import CssFlexBox from 'components/CssFlexBox';
import CssAutocomplete from 'components/CssAutocomplete';
import MobileFilters from './MobileFilters';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledIconButton = styled(IconButton)({
  top: '-10px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Clients = () => {
  const theme = useTheme();
  const location = useLocation();
  const clientsPage = location.state?.clientPage;
  const { dateRange, searchQuery, isSearchClosed, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, setSearchQuery, setIsSearchClosed, setEnableSearchField, clientStatusFilters, setClientStatusFilters, isUpdatePage, setIsUpdatePage, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, defaultFilterDates, setDefaultFilterDates, dateSelectedFilter, setDateSelectedFilter, clientFilterQuery, setClientFilterQuery, setClientPage, userPermissions,  autocompleteIds, setAutocompleteIds, setIsAutocompleteFilterApplied, isChangeAutocompleteIds, setIsChangeAutocompleteIds } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const navigate = useNavigate();
  const { getClientsApi, getClientsDownloadApi, getCountriesApi, postActivityLogApi } = useRequests();
  const getClients = useApi(getClientsApi);
  const getClientsDownload = useApi(getClientsDownloadApi);
  const getCountries = useApi(getCountriesApi);
  const postActivityLog = useApi(postActivityLogApi);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('registrationDate');
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(clientsPage ? clientsPage : 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [openFiltersScreen, setOpenFiltersScreen] = useState<boolean>(false);
  const [enableButtons, setEnableButtons] = useState<boolean>(true);
  const [isFiltersCleared, setIsFiltersCleared] = useState<boolean>(false);
  const [countryMenu, setCountryMenu] = useState<any>([]);
  const [countryMenuPage, setCountryMenuPage] = useState(0);
  const [countryMenuLimit] = useState(300);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadParams, setDownloadParams] = useState<any>(null);
  const downloadFileHeaders = [
    { label: 'Client ID', key: 'clientId' },
    { label: 'Created Date', key: 'registrationDate' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Gender', key: 'gender' },
    { label: 'Nationality', key: 'nationality' },
    { label: 'DOB', key: 'DateOfBirth' },
    { label: 'Birthplace', key: 'birthPlace' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Email ID', key: 'email' },
    { label: 'Regulatory Body', key: 'regulatoryBody' },
    { label: 'Market Company ', key: 'marketCompany' },
    { label: 'Brand', key: 'brand' },
    { label: 'Country Of Residence', key: 'countryOfResidence' },
    { label: 'Address', key: 'address' },
    { label: 'Tax Countries', key: 'taxCountries' },
    { label: 'Tin Number', key: 'tinNumber' },
    { label: 'Employment Status', key: 'employmentStatus' },
    { label: 'Employment Industry', key: 'employmentIndustry' },
    { label: 'Current Employer', key: 'currentEmployer' },
    { label: 'Annual Income', key: 'annualIncome' },
    { label: 'Networth', key: 'netWorth' },
    { label: 'Professional Subscriber', key: 'professionalSubscriber' },
    { label: 'PEP Info', key: 'pepInfo' },
    { label: 'Policies Agreed', key: 'policiesAggreed' },
    { label: 'KYC Status', key: 'kycStatus' },
    { label: 'Trading Acc Status', key: 'tradingAccStatus' },
    { label: 'Status', key: 'paymentStatus' },
    { label: 'Risk Rating', key: 'riskRating' },
    { label: 'Screening', key: 'screening' },
    { label: 'Screening Rating', key: 'screeningRating' },
    { label: 'Screening Score', key: 'screeningScore' },
    { label: 'Nature And Ownership Rating', key: 'natureAndOwnershipRating' },
    { label: 'Nature And Ownership Score', key: 'natureAndOwnershipScore' },
    { label: 'Jurisdiction Rating', key: 'jurisdictionRating' },
    { label: 'Jurisdiction Score', key: 'jurisdictionScore' },
    { label: 'Nature Of Business Relationship Rating', key: 'natureOfBusinessRating' },
    { label: 'Nature Of Business Relationship Score', key: 'natureOfBusinessScore' },
    { label: 'Product And Services Rating', key: 'productAndServiceRating' },
    { label: 'Product And Services Score', key: 'productAndServiceScore' },
    { label: 'Position', key: 'position' },
    { label: 'Broker', key: 'broker' },
    { label: 'Equity Trading Number', key: 'EquityTradingNumber' },
    { label: 'CFD Trading Number', key: 'CfdTradingNumber' },
    { label: 'Crypto Trading Number', key: 'CryptoTradingNumber' }
  ];
  const downloadFilename = `clients-${moment().format('MM-DD-yyyy')}`;

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setClientPage(0);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (event: MouseEvent<unknown>, profile: any) => {
    setClientId(profile.uid);
    setSearchQuery('');
    setIsSearchClosed(true);
    setEnableSearchField(false);
    navigate('/profile', { state: profile });
  };

  type Params = {
    page: number,
    limit: number,
    q?: string,
    fromDate?: string,
    toDate?: string,
    sort?: number,
    sortBy?: string,
    clientFilterQuery?: string
  }

  type DownloadParams = {
    q?: string,
    fromDate?: string,
    toDate?: string,
    clientFilterQuery?: string
  }

  const getClientsCall = (params: Params) => {
    const { page, limit, q, fromDate, toDate, sort, sortBy, clientFilterQuery } = params;
    setClientPage(page);
    getClients.request(page + 1, limit, q, fromDate, toDate, sort, sortBy, clientFilterQuery).then((res) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData(res.data);

        if (isFilterApplied) {
          enqueueSnackbar('Filters have been applied successfully.', {
            variant: 'successWithUndo',
            onUndo: onClearFilters
          });
          setIsFilterApplied(false);
        }
      }
      if (isUpdatePage) setIsUpdatePage(false);
      if (isFilterCleared) setIsFilterCleared(false);
      if (isFiltersCleared) setIsFiltersCleared(false);
      if (isChangeAutocompleteIds) setIsChangeAutocompleteIds(false);
    });
  };

  const getClientsDownloadCall = (params: DownloadParams) => {
    const { q, fromDate, toDate, clientFilterQuery } = params;

    if (!checkClientsPermissions()) return;

    const data = params;
    delete data.clientFilterQuery;
    if (clientFilterQuery) {
      const filters = _.omitBy(getQueryMap(clientFilterQuery), _.isNil);
      setDownloadParams({...data, ...filters});
    } else {
      setDownloadParams(data);
    }
    
    getClientsDownload.request(q, fromDate, toDate, clientFilterQuery).then((res) => {
      if (res.status === 200) {
        setDownloadData(res.data);
        setIsDownloadEnabled(true);
      }
    });
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    setIsDownloadEnabled(false);
    _.map(clientStatusFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if (autocompleteIds.length > 0) {
      _.map(autocompleteIds, (b: any) => {
        query += `&country=${b.value}`;
      });

      setIsAutocompleteFilterApplied(true);
    }

    if(query) {
      setClientFilterQuery(query);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onMobileCancel = () => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setDefaultFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setClientFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setClientStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
    setDateSelectedFilter('all');
    setClientPage(0);
    setPage(0);
    setAutocompleteIds([]);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(clientStatusFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setClientStatusFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const checkClientsPermissions = () => {
    return checkPermissions(userPermissions, 'CLIENTS');
  };

  const onCountryScroll = (event: any) => {
    const element = event.target;

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setCountryMenuPage(countryMenuPage + 1);
    }
  };

  const triggerActivityLog = () => {
    let payload = {
      page: 'CLIENT',
      action: 'DOWNLOAD'
    };
    if (downloadParams) {
      payload = {...payload, ...downloadParams};
    }
    postActivityLog.request(_.omitBy(payload, _.isEmpty));
  };

  useEffect(() => {
    getCountries.request(countryMenuPage + 1, countryMenuLimit).then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        setCountryMenu((cs: any) => {
          cs = cs.concat(_.map(res.data, (item, i) => {
            return {value: item.shortName, displayName: item.name};
          }));

          const csByUniq = _.uniqBy(cs, 'value');

          return csByUniq;
        });
      }
    });
  }, [countryMenuPage]);

  useEffect(() => {
    if (!searchQuery) {
      if (clientsPage) {
        setPage(clientsPage);
        location.state = {}
        getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
      } else {
        if ((filterDates && filterDates.fromDate && filterDates.toDate) || (defaultFilterDates && defaultFilterDates.fromDate && defaultFilterDates.toDate) || clientFilterQuery) {
          setPage(isUpdatePage ? 0 : page);
        }
        getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
      }
      getClientsDownloadCall({ fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
    } else if (searchQuery !== '' && !isSearchClosed) {
      if (clientsPage) {
        setPage(clientsPage);
        location.state = {}
        getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, q: searchQuery, fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
      } else {
        setPage(isUpdatePage ? 0 : page);
        getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, q: searchQuery, fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
      }
      getClientsDownloadCall({ q: searchQuery, fromDate: defaultFilterDates.fromDate || filterDates.fromDate, toDate: defaultFilterDates.toDate || filterDates.toDate, clientFilterQuery: clientFilterQuery });
    }
  }, [page, rowsPerPage, order, orderBy, isFilterCleared, clientFilterQuery, searchQuery, isSearchClosed, isFiltersCleared, isUpdatePage, clientsPage, userPermissions]);

  useEffect(() => {
    const newFilters = _.map(clientStatusFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else if ((defaultFilterDates && defaultFilterDates.fromDate && defaultFilterDates.toDate) || (dateSelectedFilter)) {
      setEnableButtons(false);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [clientStatusFilters, isFiltersCleared, filterDates, defaultFilterDates, dateSelectedFilter]);

  return (
    <CssContainer>
      {isMobile ? 
        <CssTitle variant='subtitle2' sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Clients
          <Box>
            <StyledIconButton
              onClick={handleFilters}
              aria-label='filters'
            >
              <IconFilters />
            </StyledIconButton>
            {dateRange && <>
              <CalendarBlack />
              <CssTitle variant='caption' component='span' sx={{ position: 'relative', top: '-5px', ml: .5}}>{dateRange}</CssTitle> 
            </>}
          </Box>
        </CssTitle>
      : <CssFlexBox sx={{justifyContent: 'space-between'}}>
          <CssTitle variant='h4' underline>Clients</CssTitle> 
          {isDownloadEnabled && checkClientsPermissions() ? <CSVLink data={downloadData} headers={downloadFileHeaders} filename={downloadFilename} style={{position: 'relative', top: 39, width: 30, height: 30}} onClick={triggerActivityLog}>
            <Download />
          </CSVLink> :
          <StyledIconButton disabled style={{position: 'relative', top: 40, width: 30, height: 30}}>
            <Download fill={'#E5E5E5'} />
          </StyledIconButton>}
        </CssFlexBox>
      }

      <CssTable
        headers={ClientHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        highlightText={searchQuery}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onRowClick={handleRowClick}
        sx={{ mt: {md: 3, xs: 0} }}
        onFilters={handleFilters}
        {...(!isMobile && {isDesktopFilters: true})}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
        {...(isMobile && {
          rightActionTitle: '- Clear filters',
          rightAction: onClearFilters
        })}
      >
        {isMobile ? 
          <MobileFilters onCancel={onMobileCancel} />
          :
          <>
            <StyledBox>
              <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
              <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
            </StyledBox>
            <Box sx={{m: 4, mt: 0}}>
              <CssAccordion title='Date' isExpand={(filterDates && filterDates.fromDate && filterDates.toDate) || (defaultFilterDates && defaultFilterDates.fromDate && defaultFilterDates.toDate)}>
                <MobileFilters onCancel={onMobileCancel} device='DESKTOP' />
                <Filters from={'POP_IN_FILTER'} fromLabel={'From'} toLabel={'To'} />
              </CssAccordion>
              <CssAccordion title='Country' isExpand={autocompleteIds.length > 0}>
                <CssAutocomplete label='Select country' placeholder='Select country' options={countryMenu} isGeneric={true} />
              </CssAccordion>
              {clientStatusFilters && clientStatusFilters.map((item: any, index: number) => (
                <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
                  <FormGroup>
                    {item.filters.map((filter: any, i: number) => (
                      <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                    ))}
                  </FormGroup>
                </CssAccordion>
              ))}

              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
                  <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
                  <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      </ScreenDialog>
    </CssContainer>
  );
};

export default Clients;