/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, ChangeEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, TextField, IconButton } from '@mui/material';
import SearchIcon from './icons/Search';
import Close from './icons/Close';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%', 
  justifyContent: 'space-between',
  '& .ellipse': {}
});

const CssTextField = styled(TextField)({
  width: '100%',
  '& .MuiInput-underline:after, & .MuiInput-underline:before, & .MuiInput-underline:hover:before': {
    borderBottom: 0
  },
  '&:hover': {
    borderBottom: 0
  }
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  marginRight: 20,
  top: 3,
  [theme.breakpoints.between('xs', 'sm')]: {
    marginRight: 0
  }
}));

const Search = () => {
  const location = useLocation();
  const { setSearchQuery, setIsSearchClosed, enableSearchField, setEnableSearchField, setIsUpdatePage, dashboardSelectedFilter, isWithDrawalTabSelected, isWalletTabSelected } = useContext(GlobalContext);
  const [searchHint, setSearchHint] = useState('Enter email, first name or last name');
  const excludeSearch = ['/market/profile', '/exchange/holidays', '/transaction-history'];

  const onShowSearch = () => {
    setEnableSearchField(true);
  };

  const onHideSearch = () => {
    setSearchQuery('');
    setIsSearchClosed(true);
    setEnableSearchField(false);
    setIsUpdatePage(false);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(encodeURIComponent(event?.target.value));
    setIsUpdatePage(true);
  };

  useEffect(() => {
    if (enableSearchField) {
      setIsSearchClosed(false);
    }
  }, [enableSearchField]);

  useEffect(() => {
    if (location.pathname === '/transactions' || dashboardSelectedFilter === 'DASHBOARD_FIN_OPS') {
      setSearchHint('Enter transaction ID or client ID or email or phone number or client name');
    } else if (location.pathname === '/markets') {
      setSearchHint('Enter symbol, company name, instrument type, 2nd instrument type, or tags');
    } else if (location.pathname === '/reports') {
      if (isWithDrawalTabSelected) {
        setSearchHint('Enter Report ID');
      } else if (isWalletTabSelected) {
        setSearchHint('Enter Client ID, First name or Last name');
      } else {
        setSearchHint('Enter Report ID or Report type');
      }
    } else if (location.pathname === '/orders') {
      setSearchHint('Enter order TXN ID or venue order no or client ID or client name');
    } else if (location.pathname === '/wallet-snapshot') {
      setSearchHint('Enter Client ID, First name or Last name');
    } else {
      setSearchHint('Enter email, first name, last name, phone number or client id');
    }
  }, [location, dashboardSelectedFilter, isWithDrawalTabSelected]);

  return (
    <StyledBox>
      {enableSearchField ? 
        <>
          <CssTextField 
            variant='standard' 
            placeholder={searchHint}
            InputProps={{ disableUnderline: true }}
            onChange={onSearch}
            autoFocus
          />
          <StyledIconButton onClick={onHideSearch}>
            <Close className={'close-icon'} />
          </StyledIconButton>
        </>
        : 
        <>
          <Box sx={{width: '100%', height: 38, cursor: 'pointer'}} onClick={onShowSearch}></Box>
          {!excludeSearch.includes(location.pathname) && <StyledIconButton onClick={onShowSearch} className='search'>
            <SearchIcon className={'search-icon'} />
          </StyledIconButton>}
        </>
      }
    </StyledBox>
    
  );
};

export default Search;