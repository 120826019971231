/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import CssContainer from 'components/CssContainer';
import CssBox from 'components/CssBox';
import ViewField from 'components/ViewField';
import SelectField from 'components/SelectField';
import CssTitle from 'components/CssTitle';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';
import { checkPermissions, capitalizeFirstLetterOfEachWord } from 'utils';

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  lineHeight: '32px',
  marginRight: 28,
  fontSize: '1rem',
  '&.label': {
    fontWeight: 700,
    marginRight: 12
  },
  '&:last-child': {
    marginRight: 0
  },
  '&.display-name': {
    fontSize: '2.25rem',
    fontWeight: 500,
    marginBottom: 24
  },
  '&.ALERT': {
    color: '#FF5C4E'
  },
  '&.VERIFIED': {
    color: '#02B9A3'
  },
  '&.REVIEW': {
    color: '#FFAE00'
  },
  '&.WARNING': {
    color: '#FF802C'
  }
}));

const MarketCfdProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { instrumentsCfdProfileDetails, userPermissions } = useContext(GlobalContext);
  const { getInstrumentDetailsApi, updateCfdInstrumentDetailsApi, getStockFiltersApi, getInstrumentGroupsApi } = useRequests();
  const getInstrumentDetails = useApi(getInstrumentDetailsApi);
  const updateCfdInstrumentDetails = useApi(updateCfdInstrumentDetailsApi);
  const getStockFilters = useApi(getStockFiltersApi);
  const getInstrumentGroups = useApi(getInstrumentGroupsApi);
  const [instrumentId] = useStorage<number | null>('instrumentId', null);
  const [instrumentDetailsData, setInstrumentDetailsData] = useState<any>(null);
  const [data, setData] = useState<any | null>(null);
  const [permissionCheck, setPermissionCheck] = useState<boolean>(false);
  const [instrumentSubTypeList, setInstrumentSubTypeList] = useState([]);
  const [selectedDayData, setSelectedDayData] = useState([]);
  const [instrumentGroupNameList, setInstrumentGroupNameList] = useState([]);

  const onInternalStatusChange = (event: SelectChangeEvent<unknown>, name?: string) => {
    let payload = {};

    if (name) {
      const key = (name === 'instrumentSubType') ? 'instrument_sub_type' : name;
      payload = {[key]: event.target.value};
    } else {
      payload = {internalStatus: event.target.value};
    }

    updateCfdInstrumentDetails.request(instrumentId, payload).then((res) => {
      if (res.status === 200) {
        const profile = data.map((p: any) => {
          if (p.section === 'nemoApp') {
            p.fields = p.fields.map((f: any) => {
              if (name === f.name) {
                f.value = event.target.value;
              } else if (!name && f.name === 'internalStatus') {
                f.value = event.target.value;
              }
              return f;
            });
          }
          return p;
        })
        setData(profile);
      }
    }).catch(err => {
      enqueueSnackbar(err.response.data.error.message, {
        variant: 'errorAlert'
      });
    });
  };

  const onMarketDayChange = (event: SelectChangeEvent<unknown>, property: string) => {
    setSelectedDayData((instrumentDetailsData.timeSession).filter((data: any) => data.day === event.target.value));
    setData((prevData: any) => _.map(prevData, (d, index) => {
      if (d.section === property) {
        d.fields = _.map(d.fields, (f, idx) => {
          if (f.name === 'day') {
            f.value = event.target.value
          }
          if (f.name === 'timeFrom') {
            f.value = _.find(instrumentDetailsData[property], (i) => i.day === event.target.value).timeFrom;
          }
          if (f.name === 'timeTo') {
            f.value = _.find(instrumentDetailsData[property], (i) => i.day === event.target.value).timeTo;
          }
          return f;
        });
      }

      return d;
    }));
  };

  const onFieldEdit = (event: any, name: string) => {
    const profile = data.map((p: any) => {
      if (p.fields) {
        p.fields = p.fields.map((f: any) => {
          if (f.name === name) {
            f.isEdit = true;
          }
          return f;
        });
      } else {
        if (p.name === name) {
          p.isEdit = true;
        }
      }
      return p;
    })
    setData(profile);
  };

  const onFieldUpdate = (value: string, name: string, valueType?: string) => {
    const key = (name === 'instrumentSubType') ? 'instrument_sub_type' : name;
    updateCfdInstrumentDetails.request(instrumentId, {[key]: valueType === 'INTEGER' ? parseInt(value) : value}).then((res) => {
      if (res.status === 200) {
        const profile = data.map((p: any) => {
          if (p.fields) {
            p.fields = p.fields.map((f: any) => {
              if (f.name === name) {
                f.value = value;
                f.isEdit = false;
              }
              return f;
            });
          } else {
            if (p.name === name) {
              p.value = value;
              p.isEdit = false;
            }
          }
          return p;
        })
        setData(profile);
      }
    }).catch(err => {
      enqueueSnackbar(err.response.data.error.message, {
        variant: 'errorAlert'
      });
    });
  };

  const onTagsBlur = (name: string, value: any) => {
    const profile = data.map((p: any) => {
      if (p.fields) {
        p.fields = p.fields.map((f: any) => {
          if (f.name === name) {
            f.isEdit = value ? true : false;
          }
          return f;
        });
      } else {
        if (p.name === name) {
          const edit = (_.isArray(value) ? (value.length ? true : false ) : (value ? true : false));
          p.isEdit = edit;
        }
      }
      return p;
    });
    setData(profile);
  };

  const checkMarketPermissions = () => {
    setPermissionCheck(checkPermissions(userPermissions, 'MARKETS'));
  };

  useEffect(() => {
    if (!userPermissions.length) return;
    Promise.all([
      getStockFilters.request(),
      getInstrumentGroups.request(),
      getInstrumentDetails.request(instrumentId)
    ]).then(([stockFiltersRes, instrumentGroupsRes, instrumentDetailsRes]) => {
      if (stockFiltersRes.status === 200) {
        setInstrumentSubTypeList(() => stockFiltersRes.data.instrumentSubType.CFD.map((item: string, index: number) => ({value: item, displayName: item})));
      }
      if (instrumentGroupsRes.status === 200) {
        setInstrumentGroupNameList(() => instrumentGroupsRes.data.map((item: string, index: number) => ({value: item, displayName: capitalizeFirstLetterOfEachWord(item)})));
      }
      if (instrumentDetailsRes.status === 200) {
        setInstrumentDetailsData(instrumentDetailsRes.data);
      }
    });
    checkMarketPermissions();
  }, [userPermissions]);

  useEffect(() => {
    if (instrumentSubTypeList.length > 0 && instrumentGroupNameList.length > 0) {
      setData((mps: any) => instrumentsCfdProfileDetails.map((item: any, index: number) => {
        if (item.name && instrumentDetailsData && (instrumentDetailsData[item.name] || instrumentDetailsData[item.name] === 0)) {
          if (typeof instrumentDetailsData[item.name] === 'boolean') {
            item.value = instrumentDetailsData[item.name] === true ? 'Yes' : 'No';
          } else {
            item.value = instrumentDetailsData[item.name];
          }
        } else if (item.section && item.fields) {
          item.fields = item.fields.map((f: any, i: number) => {
            if (f?.name && instrumentDetailsData && (instrumentDetailsData[f.name] || instrumentDetailsData[f.name] === 0 || !instrumentDetailsData['mtGroup'])) {
              if (typeof instrumentDetailsData[f.name] === 'boolean') {
                f.value = instrumentDetailsData[f.name] === true ? 'Yes' : 'No';
              } else if (f.isDate && moment(new Date(instrumentDetailsData[f.name])).isValid()) {
                if (f.isTime) {
                  f.value = moment(instrumentDetailsData[f.name]).format('HH.mm.ss');
                } else {
                  f.value = moment(instrumentDetailsData[f.name]).format('MM.DD.YYYY');
                }
              } else if (item.section === 'timeSession' && f.name === 'day' && instrumentDetailsData && instrumentDetailsData.timeSession) {
                setSelectedDayData((instrumentDetailsData.timeSession).filter((data: any) => data.day === f.value));
              } else if (item.section === 'timeSession' && f.pickValuesFrom) {
                if (f.isTime) {
                  f.value = moment(_.get(instrumentDetailsData, f.pickValuesFrom[0])).format('HH:mm');
                } else {
                  f.value = _.get(instrumentDetailsData, f.pickValuesFrom[0]);
                }
              } else if (item.section === 'nemoApp' && f.name === 'instrumentSubType') {
                f.menuItems = instrumentSubTypeList;
                if (instrumentDetailsData?.[f.name]) {
                  f.value = instrumentDetailsData[f.name];
                } else {
                  f.value = '';
                }
              } else if (item.section === 'nemoApp' && f.name === 'mtGroup') {
                f.menuItems = instrumentGroupNameList;
                if (instrumentDetailsData?.[f.name]) {
                  f.value = instrumentDetailsData[f.name];
                } else {
                  f.value = '';
                }
              } else {
                f.value = instrumentDetailsData[f.name];
              }
            } 
            return f;
          });
        }
        return item;
      }));
    }
  }, [instrumentSubTypeList, instrumentGroupNameList, instrumentDetailsData]);

  return (
    <CssContainer>
      <CssBox>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={10.5}>
            <Grid container>
              <Grid item xs={10.1} sx={{ pt: 2.5 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='display-name'>{instrumentDetailsData?.instrumentDisplayName}</StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Symbol</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.symbol}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Group</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.group}</StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Contract size</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.contractSize}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Min trade size</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.tradeMin}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Max trade size</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.tradeMax}</StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Digits</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.digits}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Volume step</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.volumeStep}</StyledTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{mt: 5.875, mb: 5.875, pr: 15}} spacing={3}>
              {data && data.map((item: any, index: number) => (
                <Fragment key={index}>
                  {item.label && 
                    <Grid item xs={(item.fullWidth ? 12 : 6)} sx={{pt: '0 !important', mb: 3}} key={index}>
                      {item.fieldType === 'select' ? 
                        <SelectField key={index} label={item.label} value={item.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(permissionCheck && (item.isEdit === false || item.isEdit === true) && {edit: item.isEdit})} menu={item.menuItems} onChange={onInternalStatusChange} permissionCheck={permissionCheck} />
                        :
                        <ViewField key={index} label={item.label} value={item.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(item.displayZero && {isDisplayZero: true})} {...(permissionCheck && (item.isEdit === false || item.isEdit === true) && {edit: item.isEdit, onEdit: onFieldEdit, onUpdate: onFieldUpdate})} name={item.name} {...(item.isAutocomplete && {enableAutocomplete: item.isAutocomplete, helperText: 'Please press ENTER to create tags.', onAutocompleteBlur: onTagsBlur})} permissionCheck={permissionCheck} />
                      }
                    </Grid>
                  }
                  <>
                    {item.section && 
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} sx={{mt: 4.375, mb: 3.5, pt: '0 !important'}}>
                          <CssTitle variant='h6' underline>{item.title}</CssTitle>
                        </Grid>
                        {item.fields && item.fields.map((f: any, i: number) => (
                          <Fragment key={i}>
                            {f.label && 
                              <Grid item xs={(f.fullWidth ? 12 : 6)} sx={{pt: '0 !important', mb: 3, ...(f.wrapElement && {maxWidth: '100% !important', flexBasis: '100% !important'})}}>
                                {f.fieldType === 'select' ? 
                                  <SelectField key={i} name={f.name} label={item[f.label] ? item[f.label] : f.label} value={f.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(f.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(permissionCheck && (f.isEdit === false || f.isEdit === true) && {edit: f.isEdit})} menu={f.menuItems} onChange={(item.section === 'timeSession' && f.name === 'day') ? (event) => onMarketDayChange(event, item.section) : onInternalStatusChange} permissionCheck={permissionCheck} noEdit={f.noEdit} />
                                  :
                                  <>{f.isTime && selectedDayData.length > 0 ? 
                                    <>{selectedDayData.map((data: any, index: number) => (
                                      <Grid key={index} container justifyContent={'space-between'}>
                                        <Grid item xs={5.5} sx={{pt: '0 !important', mb: 3, mr: 3}}>
                                          <ViewField label={'Time from'} value={moment.utc(data.timeFrom).format('HH:mm')} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} name={f.name} />
                                        </Grid>
                                        <Grid item xs={5.5} sx={{pt: '0 !important', mb: 3}}>
                                          <ViewField label={'Time to'} value={moment.utc(data.timeTo).format('HH:mm')} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} name={f.name} />
                                        </Grid>
                                      </Grid>
                                    ))}</> : 
                                    <>{(f.name !== 'timeFrom' && f.name !== 'timeTo') && <ViewField key={i} label={item[f.label] ? item[f.label] : f.label} value={f.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(f.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(f.displayZero && {isDisplayZero: true})} {...(permissionCheck && (f.isEdit === false || f.isEdit === true) && {edit: f.isEdit, onEdit: onFieldEdit, onUpdate: onFieldUpdate})} name={f.name} {...(f.isDate && {isDate: f.isDate})} {...(f.isPercentage && {isPercentage: true})} {...(f.valueType && {valueType: f.valueType})} {...(f.isAutocomplete && {enableAutocomplete: f.isAutocomplete, helperText: 'Please press ENTER to create tags.', onAutocompleteBlur: onTagsBlur})} permissionCheck={permissionCheck} />}</>
                                }</>}
                              </Grid>
                            }
                          </Fragment>
                        ))}
                      </Grid>
                    }
                  </>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CssBox>
    </CssContainer>
  );
};

export default MarketCfdProfile;